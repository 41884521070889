import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Observable } from 'rxjs';
import { GuiActions } from '../actions/gui.actions';
import { selectConfirmDialogState, selectLoaders, selectLoading } from '../selectors/gui.selectors';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class GuiFacade {
    loaders$ = this.store.select(selectLoaders);

    constructor(
        private store: Store,
        private translateService: TranslateService,
    ) {}

    showSuccessMessage(msg: string): void {
        this.store.dispatch(GuiActions.showSuccessToastAction({ options: { summary: this.translateService.instant(msg) as string } }));
    }

    showErrorMessage(msg: string): void {
        this.store.dispatch(GuiActions.showErrorToastAction({ options: { summary: msg } }));
    }

    showConfirmDialog(): void {
        this.store.dispatch(GuiActions.openConfirmDialog());
    }

    hideConfirmDialog(): void {
        this.store.dispatch(GuiActions.closeConfirmDialog());
    }

    selectConfirmDialog(): Observable<boolean> {
        return this.store.select(selectConfirmDialogState);
    }

    showLoader(id: string) {
        this.store.dispatch(GuiActions.addLoader({ id }));
    }

    hideLoader(id: string) {
        this.store.dispatch(GuiActions.removeLoader({ id }));
    }

    isLoading$(): Observable<boolean> {
        return this.store.select(selectLoading).pipe(distinctUntilChanged());
    }
}
