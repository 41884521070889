import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpinnerIconComponent } from './components/spinner-icon/spinner-icon.component';

@NgModule({
    imports: [CommonModule, ProgressSpinnerModule],
    declarations: [SpinnerComponent, SpinnerIconComponent],
    exports: [SpinnerComponent, SpinnerIconComponent],
})
export class SpinnerModule {}
