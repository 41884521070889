import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SharedModule } from '@app/shared/shared.module';

import * as fromGui from './store/reducers/gui.reducer';

import { ToastsComponent } from './components/toasts/toasts.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EffectsModule } from '@ngrx/effects';
import { GuiEffects } from './store/effects/gui.effects';
import { LoaderService } from './services/loader.service';
import { SpinnerModule } from '@app/shared/components/spinner';

@NgModule({
    providers: [MessageService, ConfirmationService, LoaderService],
    declarations: [ToastsComponent, ConfirmDialogComponent],
    imports: [
        CommonModule,
        SharedModule,
        SpinnerModule,
        EffectsModule.forFeature([GuiEffects]),
        StoreModule.forFeature(fromGui.GUI_FEATURE_KEY, fromGui.guiReducer),
    ],
    exports: [ToastsComponent, ConfirmDialogComponent, SpinnerModule],
})
export class GuiModule {}
