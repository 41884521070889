import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { GuiActions } from '../actions/gui.actions';
import { ToastTypesEnum } from '../../enums/toast-types.enum';

@Injectable()
export class GuiEffects {
    showSuccessToast$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(GuiActions.showSuccessToastAction),
                tap((action) => {
                    this.toastsService.add({
                        severity: ToastTypesEnum.SUCCESS_TOAST,
                        summary: action.options.summary,
                        detail: action.options.detail,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    showInfoToast$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(GuiActions.showInfoToastAction),
                tap((action) => {
                    this.toastsService.add({
                        severity: ToastTypesEnum.INFO_TOAST,
                        summary: action.options.summary,
                        detail: action.options.detail,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    showWarnToast$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(GuiActions.showWarningToastAction),
                tap((action) => {
                    this.toastsService.add({
                        severity: ToastTypesEnum.WARNING_TOAST,
                        summary: action.options.summary,
                        detail: action.options.detail,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    showErrorToast$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(GuiActions.showErrorToastAction),
                tap((action) => {
                    this.toastsService.add({
                        severity: ToastTypesEnum.ERROR_TOSAT,
                        summary: action.options.summary,
                        detail: action.options.detail,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private toastsService: MessageService,
    ) {}
}
