import { createReducer, on } from '@ngrx/store';
import { GuiActions } from '../actions/gui.actions';

export const GUI_FEATURE_KEY = 'guiModule';

export interface GuiState {
    showPopup: boolean;
    confirmDialogIsOpen: boolean;
    loaders: string[];
    loading: boolean;
}

export const initialState: GuiState = {
    showPopup: false,
    confirmDialogIsOpen: false,
    loaders: [],
    loading: false,
};
export const guiReducer = createReducer(
    initialState,
    on(
        GuiActions.openConfirmDialog,
        (state): GuiState => ({
            ...state,
            confirmDialogIsOpen: true,
        }),
    ),
    on(
        GuiActions.closeConfirmDialog,
        (state): GuiState => ({
            ...state,
            confirmDialogIsOpen: false,
        }),
    ),
    on(
        GuiActions.addLoader,
        (state, action): GuiState => ({
            ...state,
            loaders: [...state.loaders, action.id],
            loading: true,
        }),
    ),
    on(GuiActions.removeLoader, (state, action): GuiState => {
        const ids = [...state.loaders];
        const index = ids.indexOf(action.id);
        if (index !== -1) {
            ids.splice(index, 1);
        }
        return {
            ...state,
            loaders: ids,
            loading: ids.length > 0,
        };
    }),
);
