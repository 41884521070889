import { createAction, props } from '@ngrx/store';
import { IToastModel } from '../../models/toast';

export enum GuiActionsTypes {
    SHOW_SUCCESS_TOAST_ACTION = '[GUI] Show success toast',
    SHOW_INFO_TOAST_ACTION = '[GUI] Show info toast',
    SHOW_WARNING_TOAST_ACTION = '[GUI] Show warning toast',
    SHOW_ERROR_TOAST_ACTION = '[GUI] Show error toast',
    OPEN_CONFIRM_DIALOG_ACTION = '[GUI] Show confirm dialog',
    CLOSE_CONFIRM_DIALOG_ACTION = '[GUI] Close confirm dialog',
    ADD_LOADER = '[GUI] Add Loader',
    REMOVE_LOADER = '[GUI] Remove Loader',
    CLEAR_ACTION = '[GUI] Clear',
}

const showSuccessToastAction = createAction(GuiActionsTypes.SHOW_SUCCESS_TOAST_ACTION, props<{ options: IToastModel }>());
const showInfoToastAction = createAction(GuiActionsTypes.SHOW_INFO_TOAST_ACTION, props<{ options: IToastModel }>());

const showWarningToastAction = createAction(GuiActionsTypes.SHOW_WARNING_TOAST_ACTION, props<{ options: IToastModel }>());
const showErrorToastAction = createAction(GuiActionsTypes.SHOW_ERROR_TOAST_ACTION, props<{ options: IToastModel }>());

const openConfirmDialog = createAction(GuiActionsTypes.OPEN_CONFIRM_DIALOG_ACTION);
const closeConfirmDialog = createAction(GuiActionsTypes.CLOSE_CONFIRM_DIALOG_ACTION);

const addLoader = createAction(GuiActionsTypes.ADD_LOADER, props<{ id: string }>());
const removeLoader = createAction(GuiActionsTypes.REMOVE_LOADER, props<{ id: string }>());

const clearAction = createAction(GuiActionsTypes.CLEAR_ACTION);

export const GuiActions = {
    showSuccessToastAction,
    showInfoToastAction,
    showWarningToastAction,
    showErrorToastAction,
    openConfirmDialog,
    closeConfirmDialog,
    addLoader,
    removeLoader,
    clearAction,
};
