import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { GuiFacade } from '../store/facades/gui-facade';
import { SpinnerComponent } from '@app/shared/components/spinner';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private renderer2!: Renderer2;
    private componentRef!: ComponentRef<SpinnerComponent>;

    constructor(
        private guiFacade: GuiFacade,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private rendererFactory2: RendererFactory2,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {}

    init(): void {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.guiFacade
            .isLoading$()
            .pipe(
                debounceTime(50),
                distinctUntilChanged(),
                tap((value) => {
                    if (value) {
                        this.show();
                    } else {
                        this.hide();
                    }
                }),
            )
            .subscribe();
    }

    private show(): void {
        this.componentRef = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent).create(this.injector);
        this.applicationRef.attachView(this.componentRef.hostView);
        this.renderer2.appendChild(document.querySelector('body'), this.componentRef.location.nativeElement);
    }

    private hide(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }
}
