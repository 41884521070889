import { Component, HostBinding } from '@angular/core';
import { OpenCloseSpinner } from '../../animation/spinner.animation';

@Component({
    selector: 'app-spinner-icon',
    templateUrl: './spinner-icon.component.html',
    styleUrls: ['./spinner-icon.component.scss'],
    animations: [OpenCloseSpinner],
})
export class SpinnerIconComponent {
    @HostBinding('@openCloseSpinner') get(): void {}
}
