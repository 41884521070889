import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGuiModule from '../reducers/gui.reducer';

export const GUI_MODULE_FEATURE_STATE_KEY = fromGuiModule.GUI_FEATURE_KEY;

export type GuiModuleState = fromGuiModule.GuiState;

export const selectGuiState = createFeatureSelector<GuiModuleState>(GUI_MODULE_FEATURE_STATE_KEY);

export const selectConfirmDialogState = createSelector(selectGuiState, (state: GuiModuleState) => state.confirmDialogIsOpen);

export const selectLoaders = createSelector(selectGuiState, (state: GuiModuleState) => state.loaders);

export const selectLoading = createSelector(selectGuiState, (state: GuiModuleState) => state.loading);
